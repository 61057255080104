import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import SignUpForm from "../utilities/SignUpForm";

const Subscribe = ({ location }) => {
  return (
    <Layout location={location}>
      <section className="relative">
        <div className="pt-32 pb-12 md:pt-40 md:pb-20">
          <div className="max-w-3xl mx-auto text-center">
            <h1 className="h2 mb-4">
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-purple-500 to-green-400">
                Subscribe
              </span>{" "}
              for our computer vision & other content. We don't spam.
            </h1>
          </div>
        </div>

        <div className="pb-40 md:pb-40">
          <div className="flex justify-center max-w-3xl mx-auto">
            <SignUpForm />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Subscribe;

export const Head = () => (
  <SEO
    title="Encord | Subscribe"
    description="Subscribe to our newsletter for the latest updates on our data platform for computer vision."
  />
);
